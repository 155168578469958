@import "typeface-ibm-plex-sans";
@import "typeface-roboto";
@import "typeface-montserrat";
@import "normalize-scss";

.root {
  height: 100%;
  width: 100%;
  display: flex;
}

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #61dafb;
}

p {
  color: #fff;
  margin: 0.2vw;
  font-family: "Montserrat", sans-serif;
}
