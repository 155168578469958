/* ------- */
/* BROWSER */
/* ------- */
.App {
  background: url("static/philadelphia.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  .layer {
    background: linear-gradient(45deg, black, transparent);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    opacity: 0.7;
  }

  .title {
    color: white;
    font-size: 3.8vw;
    text-align: center;
    font-weight: 500;
  }
  .linkText {
    opacity: 1;
    color: black;
  }
}

.AppContent {
  text-align: center;
  width: 100vw;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);

  .logo {
    width: 100%;
    max-width: 9vw;
    height: auto;
  }
}

.missionStatement {
  color: white;
  font-size: 2.3vh;
  text-align: center;
  font-style: italic;
  margin: 0 17vw;
}
